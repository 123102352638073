export default {
  search_conditions: {
    reset: 'リセットする',
    search: '検索',
    partial_match: '（部分一致で検索）',
    not_selected: '指定しない',
    order: {
      description: '並び順を変更します',
      created_at_desc: '登録が新しい順',
      created_at_asc: '登録が古い順',
      updated_at_desc: '更新日が新しい順',
      updated_at_asc: '更新日が古い順',
      lexical_order_desc: '五十音順（降順）',
      lexical_order_asc: '五十音順',
    },
  },
  anywhere_store: {
    back: '戻る',
    no_contents: '該当するコンテンツが見つかりませんでした',
    find_by_conditions: '条件で探す',
    exit: '終了する',
    hotels: 'ホテル',
    visit: {
      staff_status: {
        listening: 'この物件についてビデオ通話できく',
        requesting: '問い合わせ中...',
        waiting_video: 'ビデオ接続中...',
        established: '通話中',
      },
      cancel: 'キャンセル',
      no_attendant: 'ただいま接客できるスタッフがいません',
      recording_notice:
        '※接客品質向上の為、通話内容は録画させて頂くことがあります。予めご了承下さい。',
    },
    notify: {
      STAFF_FINISHED_REASON: {
        UNLOAD: 'スタッフ側の接続が途切れました',
        DISCONNECT: 'ご案内が終了しました',
        DEFAULT: '不明な理由でスタッフ側の接続が途切れました',
      },
      CONNECTED: '{name}が通話に参加しました',
      DISCONNECTED: '{name}が退出しました',
    },
  },
  _model: {
    place: {
      parent: {
        name: '建物名',
      },
      child: {
        delete: '選択削除',
        status: 'ステータス',
        name: '部屋名',
        place_medium: '図面',
        points: '画像',
        panorama: 'パノラマ',
        qr: 'QRコード',
        qr_atbb: '　QR　コード',
        photomovie: 'フォトムービー',
        factsheet: 'チラシ',
        outsourcing_management: '外注管理',
        factsheet_atbb: 'VR用 チラシ',
        attributes: '物件情報',
        column_map: '間取り図',
        public: '公開中',
        private: '非公開',
        public_requesting: '公開依頼中',
        public_rejected: '非公開（却下）',
      },
    },
  },
  vr_control: {
    toggle_place_list: {
      open: '一覧を表示',
      close: '一覧を閉じる'
    },
    unsupported_browsers: '未対応ブラウザです。最新のブラウザで閲覧してください。',
    contents_list: {
      count_label: '物件',
      count_unit: '件',
      search_specific_attribute: '検索',
      current_place_label: '現在表示中の物件',
      search: {
        label: '条件で絞り込む',
        button: '検索条件',
      },
      filter: {
        label: 'さらに絞り込む',
        placeholder: '物件名・物件コードなど',
      },
      pinned_only_label: '★のみ表示',
      tab: {
        all: '検索結果',
        list: 'リスト',
        map: 'マップ',
      },
      not_exist_place_list: '物件が存在しません',
      load_from_pinned: '「★を読み込む」からリストを読み込んで下さい',
      not_exist_map_data: '地図表示できる物件が存在しません',
      map_control: {
        landmark_search: {
          placeholder: 'ランドマーク検索'
        },
        google_maps: {
          info_window: {
            organization_name: '店舗',
            address: '所在地',
            switch_button: '切り替える'
          },
        },
      },
      list_control: {
        save_list_button: '★を保存する',
        read_list_button: '★を読み込む',
        save_list_title: 'リストを保存',
        save_as_new: '新しく名前をつけて保存',
        personal: '個人',
        organization_group: '店舗',
        list_name_placeholder: 'リスト名',
        save_button: '保存',
        save_as_existing_list: '既存リストに上書き',
        no_select_option: '選択してください',
        read_list_title: '読み込むリストを選んで下さい',
        save_message: '保存しました',
        blank_list_name: 'リスト名を入力して下さい',
        not_select_list: 'リストを選択して下さい',
      },
      list_item_building: {
        display: '表示',
        show_more: 'もっと見る',
        count_unit: '件',
        item_room: {
          show_external: '物件の詳細を表示します',
          organization_group: '店舗：',
          place_name: '部屋名：',
          pin_button_content: {
            enabled: '★',
            disabled: '☆',
          },
          select_place_button: '切替',
          detail_button: '詳細',
          exist_pre_download_settings: '事前ダウンロード設定あり',
        },
      },
      detail_modal: {
        id: 'ID：',
        organization_group: '店舗：',
        parent_name: '建物名：',
        place_name: '部屋名：',
        address: '所在地：',
      },
    },
    device_select: {
      select_viewer: 'ビューワーを選択して下さい',
      no_connection: '接続しない',
    },
    skip_ad: '広告をスキップする/Skip this AD',
    panorama: {
      loading: '読込中',
      playbackable_account_count: '動画再生可能参加者数',
      confirm_playback: '動画読込中の参加者がいます。動画を再生しますか？',
      no_select_message: {
        please_select: '左の物件リストから物件を選択してください。'
      }
    },
    floor_plan: {
      toggle_control: {
        open: '開く',
        close: '閉じる',
      },
      control_menu: {
        close: '閉じる',
        open: '開く',
        move: '移動',
      },
    },
  },
  places: {
    index: {
      title: '登録物件一覧',
      number_of_place_children: '{number}戸',
      sub_title: '登録物件の一覧です',
      add_child: 'この物件に部屋を追加する',
      add_child_photomovie: 'この建物に物件を追加する(フォトムービー)',
      parent_edit: '建物情報を編集する',
      parent_add_items: '共用部の画像を登録する',
      parent_add_items_tooltip: 'パノラマに共用部の画像が表示されます',
      child_edit: '編集',
      child_edit_description: '編集ページに移動します',
      child_edit_no_permission: '編集権限がありません',
      child_detail: '詳細',
      child_detail_description: '詳細ページに移動します',
      child_register: '登録',
      child_display_panorama: '表示',
      child_display_panorama_settings: '設定',
      current_conditions: '現在の検索条件',
      status_no_image: '画像未登録',
      publish_without_image: '画像が未登録の為公開状態にできません',
      status_no_external_id: '{物件番号}未入力',
      publish_without_external_id:
        '{物件番号}が未入力のため公開状態にできません',
      publish: '公開する',
      publish_description: 'クリックで公開にします',
      reset_conditions: 'リセットする',
      public_request: '公開依頼',
      unpublish: '非公開にする',
      public_request_confirming: '内容確認',
      public_request_confirming_description: 'クリックで内容確認',
      public_request_cancel: '取り下げ',
      unpublish_description: 'クリックで非公開にします',
      page_count: '{number}ページ目',
      filter_condition: '絞込条件',
      filter_description: '条件を指定して検索します',
      map: {
        overwrite:
          '別の間取り図をアップロードして上書きします<br />（ドラッグ＆ドロップ対応）',
        new: '間取り図をアップロードします<br />（ドラッグ＆ドロップ対応）',
      },
      map_point: {
        new: 'VR画像登録画面に移動します<br/>（ドラッグ＆ドロップ対応）',
        home_staging_label: 'ホームステージング',
        renovation_label: "リノベーション",
        home_staging_and_renovation_label: "ホムステ・リノベ",
        home_staging_description:
          'VRホームステージングとVR家具消しの<br/>発注画面に移動します',
        renovation_description:
          "バーチャルリノベーションの<br/>発注画面に移動します",
        home_staging_and_renovation_description:
          "VRホームステージング・VR家具消しとバーチャルリノベーションの<br/>発注画面に移動します",
      },
      panorama_preview: {
        open_capture: 'VR画像のキャプチャ画面を開きます',
        open_settings: 'パノラマの設定画面を開きます',
        open_thumbnail: 'パノラマのサムネイル設定画面を開きます',
        disable_capture: '天球画像が無いためキャプチャできません',
        no_image: 'まだ画像が無いため開けません',
        no_panorama_image: 'まだ天球画像が無いため開けません',
        display: 'パノラマ画面を確認します',
        delegate_display: 'リンクしたパノラマ画面を確認します',
      },
      order: {
        description: '並び順を変更します',
        created_at_desc: '登録が新しい順',
        created_at_asc: '登録が古い順',
        updated_at_desc: '更新が新しい順',
        updated_at_asc: '更新が古い順',
      },
      show_count: {
        description: '1ページあたりの表示件数を変更します',
        show_place_count10: '10件',
        show_place_count20: '20件',
        show_place_count50: '50件',
        show_place_count100: '100件',
      },
      panorama_modal: {
        edit_airtag: 'エアタグ編集',
        settings: '設定',
        panorama_url: 'パノラマURL',
        copy_to_clipboard: 'クリップボードにコピー',
        embed_code: '埋め込みコード',
        embed_mobile_code: 'モバイル推奨埋め込みコード',
        panoramas_js: '埋め込みJavaScript',
        panoramas_js_description: 'WEBサイトの<head></head>タグの内部にこのJavaScriptを埋め込むことで、iPhoneなどのフルスクリーンAPI非対応端末でもパノラマ画面を最大表示できます。',
        display_size: '画面サイズ',
        embed_description: 'WEBサイトにこのコードを埋め込むことで、パノラマ画面を表示できます。',
        embed_mobile_description: 'WEBサイトにこのコードを埋め込むことで、モバイル端末に適したパノラマ画面を表示できます。',
        pass_code: 'パスコード',
        heatmap: 'ヒートマップ',
        no_heatmap: 'ヒートマップがありません',
        expires: '有効期限',
        preview_url: 'プレビューURL'
      }
    },
    tasks: {
      label: 'タスク',
      description: '同じ操作を連続して繰り返す時に<br/>便利なリストです',
      none: '選択しない',
      medium: '図面連続登録リスト',
      map: '間取り図連続登録リスト',
      points: 'VR画像撮影リスト',
      public: '物件公開リスト',
    },
  },
  group_calls: {
    you: 'あなた',
    unread_messages_from_here: 'ここから未読メッセージ',
    shared_medium_kind: {
      site: '外部サイト',
      media: '動画・画像',
      place: '物件・バーチャルツアー',
    },
    shared_medium_value: {
      site: '外部サイトURL',
      media: '動画・画像URL',
      place: '物件ID',
    },
    shared_medium_placeholder: {
      site: 'https://example.com',
      media: 'https://example.com/video.mp4',
      place: '物件ID (数字)',
    },
  },
}
